import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ToastContext, DialogContext } from '../../../shared/contexts'
import { client } from '../../../shared/apiClient'
import { debounce } from '@mui/material/utils'
import ButtonAdd from '../../../shared/components/ButtonAdd'
import NanoTable from '../../../shared/components/NanoTable'
import SimpleCell from '../../../shared/components/SimpleCell'
import AdminDeviceContentGroupCell from './AdminDeviceContentGroupCell'
import MoreActionAdminDeviceContentType from './MoreActionAdminDeviceContentType'
import MoreActionAdminDeviceContent from './MoreActionAdminDeviceContent'
import MoreActionAdminDrug from './MoreActionAdminDrug'
import MoreActionAdminSupplement from './MoreActionAdminSupplement'
import ImportListModal from './ImportListModal'
import { useWorkspaceSettingsStore } from '../../../shared/store'
import FilterBar from '../../../shared/components/FilterBar'
import ButtonWhite from '../../../shared/components/ButtonWhite'
import AdminDrugsGroupCell from './AdminDrugsGroupCell'
import AdminSupplementGroupCell from './AdminSupplementGroupCell'
import AdminDataModal from './AdminDataModal'
const propTypes = {}

const defaultProps = {}

const columnsDeviceContent = (handleEdit, filterContentByGroup) => {
  const settingsAsBool = filterContentByGroup === 'true'
  return [
    {
      header: 'device_content',
      width: 400,
      format: (row) => <SimpleCell loading={row.loading} data={row.device_content} />,
    },
    {
      header: 'group_other',
      width: 800,
      hide: !settingsAsBool,
      format: (row, key) => (
        <AdminDeviceContentGroupCell loading={row.loading} key={key} {...row} />
      ),
    },
    {
      header: 'actions',
      format: (row) => (
        <MoreActionAdminDeviceContent id={row.id} handleEdit={() => handleEdit(row)} />
      ),
      width: 55,
    },
  ]
}

const columnsDeviceContentType = (t, handleEdit) => [
  {
    header: 'device_content_type',
    width: 250,
    format: (row) => <SimpleCell loading={row.loading} data={row.device_content_type} />,
  },
  {
    header: 'min_quantity_tons',
    width: 250,
    format: (row) => (
      <SimpleCell
        loading={row.loading}
        data={t('number_workspace_filling_unit', { value: row.min_quantity_tons })}
      />
    ),
  },
  {
    header: 'actions',
    format: (row) => (
      <MoreActionAdminDeviceContentType id={row.id} handleEdit={() => handleEdit(row)} />
    ),
    width: 55,
  },
]

const columnsDrug = (handleEdit, filterContentByGroup) => {
  const settingsAsBool = filterContentByGroup === 'true'
  return [
    {
      header: 'drug',
      width: 250,
      format: (row) => <SimpleCell loading={row.loading} data={row.drug} />,
    },
    {
      header: 'group_other',
      width: 800,
      hide: !settingsAsBool,
      format: (row, key) => <AdminDrugsGroupCell loading={row.loading} key={key} {...row} />,
    },
    {
      header: 'actions',
      format: (row) => <MoreActionAdminDrug id={row.drug_id} handleEdit={() => handleEdit(row)} />,
      width: 55,
    },
  ]
}

const columnsSupplement = (handleEdit, filterSupplementByGroup) => {
  const settingsAsBool = filterSupplementByGroup === 'true'
  return [
    {
      header: 'supplement',
      width: 250,
      format: (row) => <SimpleCell loading={row.loading} data={row.supplement} />,
    },
    {
      header: 'group_other',
      width: 800,
      hide: !settingsAsBool,
      format: (row, key) => <AdminSupplementGroupCell loading={row.loading} key={key} {...row} />,
    },
    {
      header: 'actions',
      format: (row) => (
        <MoreActionAdminSupplement id={row.supplement_id} handleEdit={() => handleEdit(row)} />
      ),
      width: 55,
    },
  ]
}
function AdminDataPage() {
  const [valueTab, setValueTab] = useState(0)
  const [dataType, setDataType] = useState([])
  const [dataFeed, setDataFeed] = useState([])
  const [dataDrug, setDataDrug] = useState([])
  const [dataSupplement, setDataSupplement] = useState([])
  const [dataLoading, setDataLoading] = useState(true)
  const [formIsOpen, setFormIsOpen] = useState(false)
  const [formImportIsOpen, setFormImportIsOpen] = useState(false)
  const [defaultValues, setDefaultValues] = useState({})
  const [remoteRowCount, setRemoteRowCount] = useState(15)
  const [searchDeviceContent, setSearchDeviceContent] = useState(null)
  const [searchDeviceContentType, setSearchDeviceContentType] = useState(null)
  const [searchDrugs, setSearchDrugs] = useState(null)
  const [searchSupplement, setSearchSupplement] = useState(null)
  const [page, setPage] = useState(1)
  const { t } = useTranslation()
  const toastContext = useContext(ToastContext)
  const state = useWorkspaceSettingsStore()
  const filterContentByGroup = state.getSetting('filterContentByGroup')?.value
  const showDrugs = state.getSetting('showDrugs')?.value === 'true'
  const showSupplements = state.getSetting('showSupplements')?.value === 'true'

  const handleChange = (_, newValue) => {
    setValueTab(newValue)
    setSearchDeviceContent(null)
    setSearchDeviceContentType(null)
    setSearchDrugs(null)
    setSearchSupplement(null)
  }
  const fetchValue = async (v) => {
    setDataLoading(true)
    const query = { page: 1, page_size: 15, search: v }

    const result = await client.GET('/v1/workspace/device-contents', { params: { query } })
    const { results, ...pagination } = result.data
    setDataFeed(results.sort((a, b) => b.groups.length - a.groups.length))
    setRemoteRowCount(pagination.rowCount)
    setDataLoading(false)
  }
  const getOptionsDelayed = useMemo(() => debounce(fetchValue, 500), [])

  const getData = async (v) => {
    setDataLoading(true)

    setPage(1)
    const query = { page: 1, page_size: 15, search: v }

    const responses = await Promise.all([
      client.GET('/v1/workspace/device-contents', { params: { query } }),
      client.GET('/v1/workspace/device-content-types', {}),
      client.GET('/v2/drugs', {}),
      client.GET('/v2/supplements', {}),
    ])
    if (responses.length === 4) {
      setRemoteRowCount(responses[0].data.rowCount)
      setDataFeed(responses[0].data.results)
      setDataType(responses[1].data)
      setDataDrug(responses[2].data)
      setDataSupplement(responses[3].data)
    }
    setDataLoading(false)
  }

  const loadMoreRows = () => {
    const query = { page: page + 1, page_size: 15, searchDeviceContent }
    client.GET('/v1/workspace/device-contents', { params: { query } }).then((result) => {
      const { results, ...pagination } = result.data
      setDataFeed([...dataFeed, ...results])
      setPage(pagination.page)
    })
  }
  const value = useMemo(
    () => ({
      deletedOrEdited: () => {
        getData()
      },
    }),
    []
  )

  useEffect(() => {
    if (searchDeviceContent) getOptionsDelayed(searchDeviceContent)
    else if (searchDeviceContentType)
      setDataType(
        dataType?.filter((data) =>
          data.device_content_type.toLowerCase().includes(searchDeviceContentType.toLowerCase())
        )
      )
    else if (searchDrugs)
      setDataDrug(
        dataDrug?.filter((data) => data.drug.toLowerCase().includes(searchDrugs.toLowerCase()))
      )
    else if (searchSupplement)
      setDataSupplement(
        dataSupplement?.filter((data) =>
          data.supplement.toLowerCase().includes(searchSupplement.toLowerCase())
        )
      )
    else getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDeviceContent, searchDeviceContentType, searchDrugs, searchSupplement])

  const handleEdit = (row) => {
    setDefaultValues(row)
    setFormIsOpen(true)
  }

  const tabsInfo = [
    {
      value: 'device_content',
      searchFieldPlaceholder: 'search',
      searchFieldAction: setSearchDeviceContent,
      searchFieldValue: searchDeviceContent,
      filterGroups: filterContentByGroup,
    },
    {
      value: 'device_content_type',
      searchFieldPlaceholder: 'search',
      searchFieldAction: setSearchDeviceContentType,
      searchFieldValue: searchDeviceContentType,
      filterGroups: null,
    },
    showDrugs && {
      value: 'drug',
      searchFieldPlaceholder: 'search',
      searchFieldAction: setSearchDrugs,
      searchFieldValue: searchDrugs,
      filterGroups: filterContentByGroup,
    },
    showSupplements && {
      value: 'supplements',
      searchFieldPlaceholder: 'search',
      searchFieldAction: setSearchSupplement,
      searchFieldValue: searchSupplement,
      filterGroups: filterContentByGroup,
    },
  ].filter(Boolean)

  return (
    <>
      <FilterBar
        withTabs
        isSearch={tabsInfo[valueTab].searchFieldPlaceholder.length > 0}
        searchFieldPlaceholder={tabsInfo[valueTab].searchFieldPlaceholder}
        searchFieldValue={tabsInfo[valueTab].searchFieldValue}
        searchFieldAction={tabsInfo[valueTab].searchFieldAction}
      >
        <Tabs variant="scrollable" value={valueTab} onChange={handleChange}>
          {tabsInfo.map((tab) => (
            <Tab key={tab.value} sx={{ padding: 0 }} label={t(tab.value)} />
          ))}
        </Tabs>

        <ButtonAdd
          text={t('create')}
          onClick={() => {
            setFormIsOpen(true)
          }}
        />
        <ButtonWhite
          withAdd
          variant="outlined"
          text={t('import')}
          onClick={() => setFormImportIsOpen(true)}
        />
      </FilterBar>

      {tabsInfo[valueTab].value === 'device_content' && (
        <DialogContext.Provider value={value}>
          <NanoTable
            columns={columnsDeviceContent(handleEdit, filterContentByGroup)}
            data={dataFeed}
            loading={dataLoading}
            fetchPage={loadMoreRows}
            remoteRowCount={remoteRowCount}
          />
        </DialogContext.Provider>
      )}
      {tabsInfo[valueTab].value === 'device_content_type' && (
        <DialogContext.Provider value={value}>
          <NanoTable
            columns={columnsDeviceContentType(t, handleEdit)}
            data={dataType}
            loading={dataLoading}
          />
        </DialogContext.Provider>
      )}
      {tabsInfo[valueTab].value === 'drug' && (
        <DialogContext.Provider value={value}>
          <NanoTable
            columns={columnsDrug(handleEdit, filterContentByGroup)}
            data={dataDrug}
            loading={dataLoading}
          />
        </DialogContext.Provider>
      )}
      {tabsInfo[valueTab].value === 'supplements' && (
        <DialogContext.Provider value={value}>
          <NanoTable
            columns={columnsSupplement(handleEdit, filterContentByGroup)}
            data={dataSupplement}
            loading={dataLoading}
          />
        </DialogContext.Provider>
      )}
      {formIsOpen && (
        <>
          <AdminDataModal
            type={tabsInfo[valueTab].value}
            filterGroups={tabsInfo[valueTab].filterGroups}
            isOpen={formIsOpen}
            row={defaultValues}
            onSuccess={(operation) => {
              toastContext.sendMessage(
                t(`admin_${tabsInfo[valueTab].value}_snackbar_alert_${operation}`)
              )
              getData()
            }}
            onClose={() => {
              setFormIsOpen(false)
              setDefaultValues({})
            }}
          />
        </>
      )}

      {formImportIsOpen && (
        <ImportListModal
          type={tabsInfo[valueTab].value}
          isOpen={formImportIsOpen}
          onDataImported={() => getData()}
          onClose={() => {
            setFormImportIsOpen(false)
          }}
        />
      )}
    </>
  )
}

AdminDataPage.propTypes = propTypes
AdminDataPage.defaultProps = defaultProps

export default AdminDataPage
