import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Alert, Stack, TextField, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { client } from '../../../shared/apiClient'
import NanoDialog from '../../../shared/components/NanoDialog'
import SelectGroups from '../../../shared/components/SelectGroups'
import { sendEvent } from '../../../shared/utils/analyticsUtils'

const AdminDataModal = ({ isOpen, onClose, onSuccess, row, type, filterGroups }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayAlert] = useState(null)
  const { handleSubmit, control } = useForm()
  const isUpdateMode = Object.keys(row).length > 0

  const handleClose = () => {
    onClose()
  }

  const onSubmit = (data) => {
    setDisplayAlert(null)
    setIsLoading(true)

    const payload = {
      [type]: data.name,
      ...(filterGroups && { group_ids: data.group.map((gr) => gr.id) }),
      ...(type === 'device_content_type' && {
        min_quantity_tons: parseFloat(data.min_quantity_tons),
      }),
    }

    const endpoint = {
      device_content: '/v1/workspace/device-contents',
      device_content_type: '/v1/workspace/device-content-types',
      drug: '/v2/drugs',
      supplement: '/v2/supplements',
    }[type]

    const method = isUpdateMode ? 'PATCH' : 'POST'
    const url = isUpdateMode ? `${endpoint}/{id}` : endpoint

    client[method](url, {
      params: isUpdateMode ? { path: { id: row.id || row[`${type}_id`] } } : undefined,
      body: payload,
    })
      .then(() => {
        sendEvent(`${type}_${isUpdateMode ? 'updated' : 'created'}`)
        setIsLoading(false)
        handleClose()
        onSuccess(isUpdateMode ? 'updated' : 'created')
      })
      .catch((err) => {
        setDisplayAlert(err.message)
        setIsLoading(false)
      })
  }

  let defaultValues = {}
  if (isUpdateMode) {
    defaultValues = {
      name: row[type],
      groups: row.groups?.map((g) => ({
        label: g.group_name,
        id: g.group_id,
        section: 'group',
        ...g,
      })),
      ...(type === 'device_content_type' && { min_quantity_tons: row.min_quantity_tons }),
    }
  }

  return !isOpen ? null : (
    <NanoDialog
      open={isOpen}
      onClose={handleClose}
      title={isUpdateMode ? t(`edit_${type}`) : t(`create_new_${type}`)}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="name"
            defaultValue={defaultValues.name ?? ''}
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t(type)}
                autoFocus
                error={!!error}
                helperText={error?.message}
                required
                inputProps={{ maxLength: 255 }}
              />
            )}
          />
          {type === 'device_content_type' && (
            <Controller
              control={control}
              name="min_quantity_tons"
              defaultValue={defaultValues.min_quantity_tons ?? ''}
              rules={{
                valueAsNumber: true,
                validate: (value) => value >= 0 || t('specify_quantity'),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('min_quantity_tons_title')}
                  type="number"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
          {filterGroups && (
            <Controller
              control={control}
              name="group"
              defaultValue={defaultValues.groups ?? []}
              render={({ field, fieldState: { error } }) => (
                <SelectGroups
                  selectAll
                  error={error}
                  onChange={(_, data) => field.onChange(data)}
                  value={field.value}
                  required={false}
                />
              )}
            />
          )}
          {!!displayError && <Alert severity="error">{displayError}</Alert>}
          <LoadingButton loading={isLoading} type="submit" fullWidth>
            {t('validate')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

AdminDataModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  row: PropTypes.object,
  type: PropTypes.oneOf(['device_content', 'device_content_type', 'drug', 'supplement']).isRequired,
  withGroup: PropTypes.bool,
}

AdminDataModal.defaultProps = {
  isOpen: false,
  row: {},
  withGroup: false,
}

export default AdminDataModal
